import { ProcessType } from "../functions/src/types/Job";
import { MachineErrorType, MachineType } from "../functions/src/types/Machine";


export const getProcessLabel = (currentStatus: string) => {
    const index = JobProcess.findIndex((p) => p.value === currentStatus);
    if (index === -1) return;
    return JobProcess[index].label;
};

export const JobProcess: ({value: ProcessType, label:string})[] = [
    { value: "PENDING_APPROVAL", label: "Pending Approval" },
    { value: "PENDING", label: "Pending" },
    { value: "ORDER_MATERIAL", label: "Order Material" },
    { value: "CUTTING_MATERIAL", label: "Cutting Material" },
    { value: "CNC_MILLING", label: "CNC Milling" },
    { value: 'FLY_CUTTER_SURFACE', label: 'Fly Cutter Surface' },
    { value: 'P1', label: 'Process 1' },
    { value: 'P2', label: 'Process 2' },
    { value: 'P3', label: 'Process 3' },
    { value: 'P4', label: 'Process 4' },
    { value: 'P5', label: "Process 5" },
    { value: 'P6', label: "Process 6" },
    { value: 'P7', label: "Process 7" },
    { value: 'P8', label: "Process 8" },
    { value: 'P9', label: "Process 9" },
    { value: 'P10', label: "Process 10" },
    { value: 'PROGRAMMING', label: "Programming" },
    { value: 'TEMPER', label: "Temper"},
    { value: 'LAST_PROCESS', label: 'Last Process' },
    { value: 'LASER_ENGRAVING', label: 'Laser Engraving' },
    { value: 'TAPPING', label: 'Tapping' },
    { value: 'FINISHING_HOLE', label: 'Finishing Hole' },
    { value: "DEBURR_POLISH", label: "Deburr Polish" },
    { value: "GRINDING", label: "Grinding" },
    { value: "QUALITY_CONTROL", label: "Quality Control" },
    { value: "SURFACE_TREATMENT", label: "Surface Treatment" },
    { value: "PACKING", label: "Packing" },
    { value: "SHIPPING", label: "Shipping" },
    { value: "GRIND", label: "Grind" },
    { value: "GRIND_THINNESS", label: "Grind Thinness" },
    { value: "GRIND_SQ", label: "Grind SQ" },
    { value: "ROUCH_GRIND", label: "Rouch Grind" },
    { value: "ROUCH_GRIND_STAP", label: "Rouch Grind Stap" },
    { value: "FISHING_GRIND", label: "Fishing Grind" },
    { value: "FISHING_GRIND_STAP", label: "Fishing Grind Stap" },
    { value: "GRIND_ANGEL", label: "Grind Angel" },
    { value: "ROUCH_GRIND_ANGEL", label: "Rouch Grind Angel" },
    { value: "FISHING_GRIND_ANGEL", label: "Fishing Grind Angel" },
    { value: "CHEMFER", label: "Chemfer" },
    { value: "GRIND_UNDER_CUT", label: "Grind Under Cut" },
    { value: "WARPING", label: "Warping" },
    { value: "GRIND_WARPING_MARK", label: "Grind Warping Mark" },
    { value: "DUBER", label: "Duber" },
    { value: "DUBER_WARPING_MARK", label: "Duber Warping Mark" },
    { value: "COMPLETED", label: "Complete" },
    { value: "IDLE", label: "Idle" }
];

export const getErrorLabel = (currentStatus: string) => {
    const index = ErrorTypes.findIndex((p) => p.value === currentStatus);
    if (index === -1) return;
    return ErrorTypes[index].label;
};


export const ErrorTypes: ({value: MachineErrorType, label:string})[] = [
    { value: "ENDMILL_BROKEN", label: "Endmill Broken" },
    { value: "WAITING_MATERIAL", label: "Waiting Material" },
    { value: "MACHINE_ERROR", label: "Machine Error" },
    { value: "CHANGE_CUTTER", label: "Change Cutter" },
    { value: "SETTING_TOOLS", label: "Setting Tools" },
    { value: "PROGRAMMING", label: "Programming Error"}
];

export const getMachineTypeLabel = (type: string) => {
    const index = MachineTypeLabels.findIndex((p) => p.value === type);
    if (index === -1) return;
    return MachineTypeLabels[index].label;
};


export const MachineTypeLabels: ({value: MachineType, label: string})[] = [
    { value: "CNC_MACHINE", label: "CNC Machine" },
    { value: "GRINDING_MACHINE", label: "Grinding Machine" },
    { value: "TAPPING_MACHINE", label: "Tapping Machine" },
    { value: "CUTTING_MACHINE", label: "Cutting Machine" },
    { value: "MILLING_MACHINE", label: "Milling Machine" },
    { value: "POLISHING", label: "Polishing Machine" },
    { value: "DEBURR", label: "Deburr Machine" },
    { value: "QC_CONTROL", label: "QC Control" }
]