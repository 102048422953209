import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import DialogTransition from "../components/DialogTransition";
import { ViewJobDialogDynamic } from "../components/ViewJobDialog.dynamic";
import { Job, JobID } from "../functions/src/types/Job";
import { useDialog } from "./useDialog";

const useJobViewer = () => {
    const [openDialog, closeDialog] = useDialog();
    const router = useRouter();
    const isMobile = useMediaQuery('(max-width:600px)');
    
    const openJobWindow = (job: Job | JobID, moveURL=false) => {
        openDialog({
            children: <ViewJobDialogDynamic jobId={typeof job == 'string' ? job: job.id} onClose={closeDialog} />,
            fullWidth: true,
            maxWidth: 'md',
            fullScreen: isMobile,
            TransitionComponent: DialogTransition,
            onClose: () => {
                if(moveURL) router.replace('/job', undefined, { shallow: true });
            }
        })
        if(moveURL) router.replace('/job?view='+(typeof job === 'string'? job: job.id), undefined, { shallow: true });
    };

    return { openJobWindow };
}

export default useJobViewer;