import { ProcessType } from "./types/Job";
import { PlatformUser, UserClaims } from "./types/User";

export const removeKeys = (obj: any, keys:string[]): object => {
    const copy = deepCopy(obj);
    keys.forEach(key => delete copy[key]);
    return obj;
}

export const compareObjects = (obj1: any, obj2: any): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const getPlatformUser = (userToken: UserClaims | any): PlatformUser => {
    return {
        name: userToken.name,
        uid: userToken.id ?? userToken.uid ?? userToken.user_id,
        email: userToken.email
    }
}


export function deepCopy(obj:any): any {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            // @ts-ignore
            if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

export const isSyncWithMachine = (process: ProcessType) => {
    //if job is not one of
    const exclude: ProcessType[] = [
        'PENDING_APPROVAL',
        'PENDING',
        'ORDER_MATERIAL',
        'PACKING',
        'SHIPPING',
        'COMPLETED',
        'IDLE',
        'PROGRAMMING',
    ]
    return !exclude.includes(process)
} 