import { Print, Work, Mail, SupervisorAccountTwoTone, Settings, ListAltTwoTone } from '@mui/icons-material';
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import EsquireLogo from '../components/EsquireLogo'
import Layout from '../components/Layout'
import { db, docConverter, jobConverter } from '../config/firebase'
import { useAuth } from '../hooks/useAuth'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { collection, query, where } from 'firebase/firestore'
import { Job } from '../functions/src/types/Job'
import { Machine } from '../functions/src/types/Machine'
import { useDialog } from '../hooks/useDialog'
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { useSnackbar } from 'notistack';
import ProcessChip from '../components/ProcessChip';
import useJobViewer from '../hooks/useJobViewer';
import { isSyncWithMachine } from '../functions/src/utils';

const IndexPage = () => {
  const router = useRouter()
  const { user, userToken } = useAuth()
  const [jobs = [], jobsLoading, jobsError] = useCollectionData<Job>(query(collection(db, 'jobs').withConverter(jobConverter), where('status', '!=', 'COMPLETED')))
  //@ts-ignore
  const [machines = [], machinesLoading, machinesError] = useCollectionData<Machine>(collection(db, 'machines').withConverter(docConverter))
  const [openDialog, closeDialog] = useDialog();
  const { openJobWindow } = useJobViewer();
  const [stats, setStats] = useState({
    job: 0,
    newJob: 0,
    machineError: 0,
    jobError: 0,
    running: 0,
    pending: 0,
    pendingApproval: 0
  })

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (jobsError) enqueueSnackbar('Error loading jobs: ' + jobsError.message, { variant: 'error' });
  }, [jobsError])

  useEffect(() => {
    if (machinesError) enqueueSnackbar('Error loading machines: ' + machinesError.message, { variant: 'error' });
  }, [machinesError])


  useEffect(() => {
    if (!jobsLoading && !machinesLoading) {
      const jobCount = jobs.length
      const machineCount = machines.length;
      //if createdOn less than 36H, count as new
      const newJobsCount = jobs.filter(j => Math.abs(j.createdOn?.diffNow('hours')?.hours) < 36).length
      const machineErrorCount = machines.filter(m => m.hasError).length
      const jobErrorCount = jobs.filter(j => (j.errorType ?? "").length > 0).length
      const jobRunningCount = jobs.filter(j => j.multipleMachine?j.assignedMachines.some(m => isSyncWithMachine(m.status)): isSyncWithMachine(j.status)).length
      const pendingCount = jobs.filter(j => j.multipleMachine?j.assignedMachines.every(m => m.status === 'PENDING'): j.status === 'PENDING').length
      const approvalCount = jobs.filter(j => !j.approved).length
      setStats({
        job: jobCount,
        newJob: newJobsCount,
        machineError: machineErrorCount,
        jobError: jobErrorCount,
        running: jobRunningCount,
        pending: pendingCount,
        pendingApproval: approvalCount
      })
    }
  }, [jobs, machines])

  const urgentJobs = useMemo(() => {
    return jobs.filter(j => j.urgent)
  }, [jobs])

  const shippingDue = useMemo<
    {
      month: number,
      monthName: string,
      jobs: (Job & { first: boolean })[] //first if its first of the day
    }[]>(() => {
    return jobs.map(j => j.deadline?.month).filter((m, i, a) => a.indexOf(m) === i).map(m => ({
      month: m,
      monthName: new Date(2021, m - 1).toLocaleString('default', { month: 'long' }),
      jobs: jobs.filter(j => j.deadline?.month === m).sort((a, b) => a.deadline?.day - b.deadline?.day).map((j, i, a) => ({
        ...j,
        first: i === 0 || a[i - 1].deadline?.day !== j.deadline?.day
      }))
    }))
  }, [jobs])

  

  const handleViewMachineError = () => {
    const errors = machines.filter(m => m.hasError)
    openDialog({
      children: <>
        <DialogTitle>Machine Errors</DialogTitle>
        <DialogContent>
          <div className='flex flex-col'>
            {errors.map(m => <div key={m.id} className='flex flex-col'>
              <div className='flex flex-row'>
                <h2>{m.id}</h2>
              </div>
            </div>)}
            {errors.length === 0 && <h2>No errors</h2>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
          <Button onClick={() => router.push('/machine')}>View All</Button>
        </DialogActions>
      </>
    })
  }

  const handleViewRunningJobs = () => {
    const pending = jobs.filter(j => isSyncWithMachine(j.status))
    openDialog({
      children: <>
        <DialogTitle>Running Jobs</DialogTitle>
        <DialogContent>
          <div className='flex flex-col space-y-2'>
            {pending.map(j => <div key={j.id} className='flex flex-col' onClick={() => openJobWindow(j)}>
              <div className='flex flex-row justify-between'>
                <h2>{j.title} - {j.description}</h2>
                <ProcessChip process={j.status}/>
              </div>
            </div>)}
            {pending.length === 0 && <h2>None Pending</h2>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
          <Button onClick={() => router.push('/job')}>View All</Button>
        </DialogActions>
      </>
    })
  }

  const handleViewJobError = () => {
    const errors = jobs.filter(j => (j.errorType ?? "")?.length > 0)
    openDialog({
      children: <>
        <DialogTitle>Job Errors</DialogTitle>
        <DialogContent>
          <div className='flex flex-col'>
            {errors.map(m => <div key={m.id} className='flex flex-col'>
              <div className='flex flex-row'>
                <h2>{m.title} - {m.description}</h2>
              </div>
            </div>)}
            {errors.length === 0 && <h2>No errors</h2>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
          <Button onClick={() => router.push('/job')}>View All</Button>
        </DialogActions>
      </>
    })
  }

  return (
    <Layout title="Esquire">
      <div className="relative flex flex-col items-center h-full p-3 space-y-4 w-full">
        <EsquireLogo className="w-32" />
        <IconButton onClick={() => router.push("/settings")} className="absolute top-0 right-4">
          <Settings className='text-gray-600'/>
        </IconButton>
        <div className='text-left w-full p-3'>
          {user && <h4 className="font-semibold text-2xl text-cyan-800">Welcome Back {user.displayName} </h4>}
          <div className='space-y-2 text-left w-full p-3'>
            <h2 className='font-semibold text-2xl text-cyan-800'>Quick Access</h2>
            {/* Quick Access */}
            <div className='flex flex-row gap-2 w-full flex-wrap'>

              <div onClick={() => router.push("/arrangements")} className='rounded-md bg-lime-300 p-4 flex flex-col space-y-1 items-center cursor-pointer shadow-md hover:shadow-lg transition-shadow'>
                <ListAltTwoTone className='text-2xl text-lime-900' />
                <h2 className='font-semibold text-sm'>Arrangements</h2>
              </div>
              <div onClick={() => router.push("/job")} className='rounded-md bg-green-300 p-4 flex flex-col space-y-1 items-center cursor-pointer shadow-md hover:shadow-lg transition-shadow'>
                <Work className='text-2xl text-green-900' />
                <h2 className='font-semibold text-sm'>Jobs</h2>
              </div>
              <div onClick={() => router.push("/machine")} className='rounded-md bg-blue-300 p-4 flex flex-col space-y-1 items-center cursor-pointer shadow-md hover:shadow-lg transition-shadow'>
                <Print className='text-2xl text-blue-900' />
                <h2 className='font-semibold text-sm'>Machines</h2>
              </div>
              <div onClick={() => router.push("/dailyreport")} className='rounded-md bg-purple-300 p-4 flex flex-col space-y-1 items-center cursor-pointer shadow-md hover:shadow-lg transition-shadow'>
                <Mail className='text-2xl text-purple-900' />
                <h2 className='font-semibold text-sm'>Report</h2>
              </div>
              {userToken?.admin && <div onClick={() => router.push("/accounts")} className='rounded-md bg-violet-300 p-4 flex flex-col space-y-1 items-center cursor-pointer shadow-md hover:shadow-lg transition-shadow'>
                <SupervisorAccountTwoTone className='text-2xl text-violet-900' />
                <h2 className='font-semibold text-sm'>Accounts</h2>
              </div>}
            </div>
          </div>
          <div className='space-y-1 py-3'>
            <h2 className='text-xl font-semibold px-2 py-1'>Jobs</h2>
            <div className='shadow-md rounded-md w-min'>
              <div className="flex flex-row divide-x divide-gray-400">
                <div className='flex flex-col items-center p-2 cursor-pointer w-20' onClick={() => router.push('/job')}>
                  <span className="font-semibold text-2xl">{stats.newJob}</span>
                  <span className='text-center'>New</span>
                </div>
                <div className='flex flex-col items-center p-2 cursor-pointer w-20' onClick={() => router.push('/job')}>
                  <span className="font-semibold text-2xl">{stats.job - stats.pending - stats.pendingApproval}</span>
                  <span className='text-center'>Active</span>
                </div>
                {userToken?.admin && <div className='flex flex-col items-center p-2 cursor-pointer w-20' onClick={handleViewRunningJobs}>
                  <span className="font-semibold text-2xl">{stats.running}</span>
                  <span className='text-center'>Running</span>
                </div>}
              </div>
            </div>
            <h2 className='text-xl font-semibold px-2 py-1'>Errors</h2>
            <div className='shadow-md rounded-md w-min'>
              <div className="flex flex-row divide-x divide-gray-400">
                <div className='flex flex-col items-center p-2 cursor-pointer w-20' onClick={handleViewMachineError}>
                  <span className="font-semibold text-2xl">{stats.machineError}</span>
                  <span className='text-center'>Machines</span>
                </div>
                <div className='flex flex-col items-center p-2 cursor-pointer w-20' onClick={handleViewJobError}>
                  <span className="font-semibold text-2xl">{stats.jobError}</span>
                  <span className='text-center'>Job</span>
                </div>
              </div>
            </div>
            <div className='space-y-1'>
              <h2 className='text-xl font-semibold px-2 py-1 underline'>Urgent Jobs</h2>
              <div className='space-y-2 w-full max-w-sm px-3 divide-y divide-gray-200'>
                {urgentJobs.map(j => <div key={j.id} className='flex flex-col space-y-1 w-full hover:bg-gray-100 transition-colors cursor-pointer' onClick={() => openJobWindow(j.id)}>
                  <div className='flex flex-row justify-between'>
                    <h3 className='font-semibold text-lg'>{j.title}</h3>
                    <ProcessChip process={j.status!} />
                  </div>
                  <p className='text-sm'>Deadline: {j.deadline.toFormat('yyyy LL dd')}</p>
                </div>)}
                {urgentJobs.length === 0 && <h2>No Urgent Jobs</h2>}
              </div>
            </div>
            <div className='space-y-1'>
              <h2 className='text-xl font-semibold px-2 py-1 underline'>Upcoming Shipping Dates</h2>
              <div className='flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full px-3 md:divide-y-0 md:divide-x divide-y divide-gray-700'>
                {shippingDue.map(m => <div key={m.month} className='space-y-1 mt-8 first:mt-0 max-w-sm'>
                  <h3 className='text-sm uppercase underline text-gray-400'>{m.monthName}</h3>
                  {m.jobs.map(j => <div key={j.id} className='flex flex-col space-y-1 w-full'>
                    {j.first && <span className='font-semibold text-md'> {j.deadline.toFormat('dd-LL')} </span>}
                    <div className='flex flex-row justify-between hover:bg-gray-100 transition-colors cursor-pointer' onClick={() => openJobWindow(j.id)}>
                      <h3 className='font-normal'> {j.title}</h3>
                      {/* <ProcessChip process={j.status!} /> */}
                    </div>
                  </div>)}
                </div>)}
                {shippingDue.length === 0 && <h2>No Jobs Upcoming</h2>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
