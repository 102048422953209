import React, { ReactElement, ReactNode, useEffect } from "react";
import Head from "next/head";
import { BottomNavigation, BottomNavigationAction, ListItem, ListItemIcon, ListItemText, Paper, Stack, useMediaQuery } from "@mui/material";
import { Home, ListAltTwoTone, Mail, MailOutlineTwoTone, PrecisionManufacturingTwoTone, Print, Report, Settings, SupervisorAccountTwoTone, Work } from "@mui/icons-material";
import { useRouter } from "next/router";
import SlideTransition from "./SlideTransition";
import { onMessage } from "firebase/messaging";
import { messaging } from "../config/firebase";
import { useSnackbar } from "notistack";
import { useAuth } from "../hooks/useAuth";
import { UserClaims } from "../functions/src/types/User";

type Props = {
  children?: ReactNode;
  title?: string;
};

const links: {label: string, icon: ReactElement, href: string, permission?: (keyof UserClaims)[]}[] = [
  { label: "Home", icon: <Home />, href: "/" },
  { label: "Arrangement", icon: <ListAltTwoTone />, href: "/arrangements" },
  { label: "Jobs", icon: <Work />, href: "/job" },
  { label: "Machines", icon: <PrecisionManufacturingTwoTone />, href: "/machine" },
  { label: "Reports", icon: <MailOutlineTwoTone />, href: "/dailyreport" },
  { label: "Accounts", icon: <SupervisorAccountTwoTone />, href: "/accounts", permission: ["admin"] },
]

const Layout = ({ children, title = "Production Thingy" }: Props) => {
  const { userToken } = useAuth();
  const [value, setValue] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const isDesktop = useMediaQuery('(min-width:600px)');
  useEffect(() => {
    const index = links.findIndex(link => link.href == router.pathname);
    if(index != -1) setValue(index);
  }, [router.pathname])

  useEffect(() => {
    if(!messaging) return;
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      if(!payload.notification) return;
      enqueueSnackbar(payload.notification.body, {
        variant: "info",
      })
    });
  },[])

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      {isDesktop && <div className="grid grid-cols-[16rem_auto] h-screen w-screen">
        <Paper elevation={1} sx={{padding: '12px'}}>
          <div className="flex flex-col items-center justify-center h-20">
            <img src="/logo.png" className="w-20 object-cover" />
          </div>
          <Stack gap={1}>
            {links.map((link, index) => {
              if(link.permission && !link.permission.some(permission => userToken?.[permission ])) return;
              return (
                <div className={`w-full h-12 rounded-xl p-2 grid grid-cols-[24px_auto] items-center text-cyan-900 hover:bg-gray-100 cursor-pointer ${value == index ? "bg-gray-200 hover:bg-gray-300" : ""}`} key={index} onClick={() => router.push(link.href)}>
                  {link.icon}
                  <div className="flex items-center ml-4">
                    {link.label}
                  </div>
                </div>
              )
            })}
          </Stack>
        </Paper>
        <div className="py-2 min-h-screen overflow-auto">
          {children}
        </div>
      </div>}
      {!isDesktop && <SlideTransition in timeout={50}>
        <div className="min-h-[calc(100vh-56px-12px)] py-4 pb-[64px]">
          {children}
        </div>
      </SlideTransition>}
      {!isDesktop && <div className="fixed bottom-0 pb-3 left-0 right-0 z-30 bg-[#f1f5f9]">
        <BottomNavigation
          sx={{boxShadow: '0' }}
          value={value}
          onChange={(event, newValue) => {
            router.push(links[newValue].href);
          }}
        >
          {links.map((link, index) => {
            if(link.permission && !link.permission.some(permission => userToken?.[permission])) return; 
            return (
              <BottomNavigationAction sx={{padding: '6px 6px 8px', minWidth: 'auto'}} key={index} label={link.label} icon={link.icon} />
            )
          })}
        </BottomNavigation>
      </div>}
    </div>
  );
};

export default Layout;
