import { PropsWithoutRef, useMemo } from "react"
import { ProcessType } from "../functions/src/types/Job";
import { getProcessLabel } from "../utils/constants";

export default function ProcessChip({ process, padding='p-[2px_10px]' }: PropsWithoutRef<{ process: ProcessType, padding?: string}>) {
// { value: "PENDING_APPROVAL", label: "Pending Approval" },
// { value: "ORDER_MATERIAL", label: "Order Material" },
// { value: "CUTTING_MATERIAL", label: "Cutting Material" },
// { value: "CNC_MILLING", label: "CNC Milling" },
// { value: 'FLY_CUTTER_SURFACE', label: 'Fly Cutter Surface' },
// { value: 'P1', label: 'Process 1' },
// { value: 'P2', label: 'Process 2' },
// { value: 'P3', label: 'Process 3' },
// { value: 'P4', label: 'Process 4' },
// { value: 'P5', label: "Process 5" },
// { value: 'LAST_PROCESS', label: 'Last Process' },
// { value: 'TAPPING', label: 'Tapping' },
// { value: 'FINISHING_HOLE', label: 'Finishing Hole' },
// { value: "DEBURR_POLISH", label: "Deburr Polish" },
// { value: "GRINDING", label: "Grinding" },
// { value: "QUALITY_CONTROL", label: "Quality Control" },
// { value: "SURFACE_TREATMENT", label: "Surface Treatment" },
// { value: "PACKING", label: "Packing" },
// { value: "SHIPPING", label: "Shipping" },
// { value: "GRIND", label: "Grind" },
// { value: "GRIND_THINNESS", label: "Grind Thinness" },
// { value: "GRIND_SQ", label: "Grind SQ" },
// { value: "ROUCH_GRIND", label: "Rouch Grind" },
// { value: "ROUCH_GRIND_STAP", label: "Rouch Grind Stap" },
// { value: "FISHING_GRIND", label: "Fishing Grind" },
// { value: "FISHING_GRIND_STAP", label: "Fishing Grind Stap" },
// { value: "GRIND_ANGEL", label: "Grind Angel" },
// { value: "ROUCH_GRIND_ANGEL", label: "Rouch Grind Angel" },
// { value: "FISHING_GRIND_ANGEL", label: "Fishing Grind Angel" },
// { value: "CHEMFER", label: "Chemfer" },
// { value: "GRIND_UNDER_CUT", label: "Grind Under Cut" },
// { value: "WARPING", label: "Warping" },
// { value: "GRIND_WARPING_MARK", label: "Grind Warping Mark" },
// { value: "DUBER", label: "Duber" },
// { value: "DUBER_WARPING_MARK", label: "Duber Warping Mark" },
// { value: "COMPLETED", label: "Complete" },
// { value: "IDLE", label: "Idle" }
// Red
// Orange
// Amber
// Yellow
// Lime
// Green
// Emerald
// Teal
// Cyan
// Sky
// Blue
// Indigo
// Violet
// Purple
// Fuchsia
// Pink
// Rose
    const color = useMemo(() => {
        switch(process) {
            case "PENDING_APPROVAL": return "bg-gray-200 text-gray-700";
            case "PENDING": return "bg-gray-300 text-gray-800";
            case "ORDER_MATERIAL": return "bg-red-300 text-red-700";
            case "CUTTING_MATERIAL": return "bg-orange-300 text-orange-700";
            case "CNC_MILLING": return "bg-amber-300 text-amber-700";
            case 'FLY_CUTTER_SURFACE': return 'bg-yellow-300 text-yellow-700';
            case 'P1': return 'bg-lime-300 text-lime-700';
            case 'P2': return 'bg-green-300 text-green-700';
            case 'P3': return 'bg-emerald-300 text-emerald-700';
            case 'P4': return 'bg-teal-300 text-teal-700';
            case 'P5': return 'bg-cyan-300 text-cyan-700';
            case 'P6': return 'bg-sky-300 text-sky-700';
            case 'P7': return 'bg-blue-300 text-blue-700';
            case 'P8': return 'bg-indigo-300 text-indigo-700';
            case 'P9': return 'bg-violet-300 text-violet-700';
            case 'P10': return 'bg-purple-300 text-purple-700';
            case 'PROGRAMMING': return 'bg-gray-700 text-gray-300';
            case 'TEMPER': return 'bg-fuchsia-700 text-fuchsia-300';
            case 'LASER_ENGRAVING': return 'bg-rose-300 text-rose-700';
            case 'LAST_PROCESS': return 'bg-blue-300 text-blue-700';
            case 'TAPPING': return 'bg-indigo-300 text-indigo-700';
            case 'FINISHING_HOLE': return 'bg-purple-300 text-purple-700';
            case "DEBURR_POLISH": return "bg-pink-300 text-pink-700";
            case "GRINDING": return "bg-rose-300 text-rose-700";
            case "QUALITY_CONTROL": return "bg-red-700 text-red-200";
            case "SURFACE_TREATMENT": return "bg-orange-700 text-orange-200";
            case "PACKING": return "bg-yellow-700 text-yellow-200";
            case "SHIPPING": return "bg-lime-700 text-lime-200";
            case "COMPLETED": return "bg-green-700 text-green-200";
            case "IDLE": return "bg-gray-200 text-gray-700";
            case "GRIND": return "bg-emerald-700 text-emerald-200";
            case "GRIND_THINNESS": return "bg-teal-700 text-teal-200";
            case "GRIND_SQ": return "bg-cyan-700 text-cyan-200";
            case "ROUCH_GRIND": return "bg-indigo-700 text-indigo-200";
            case "ROUCH_GRIND_STAP": return "bg-violet-700 text-violet-200";
            case "FISHING_GRIND": return "bg-purple-700 text-purple-200";
            case "FISHING_GRIND_STAP": return "bg-fuchsia-700 text-fuchsia-200";
            case "GRIND_ANGEL": return "bg-pink-700 text-pink-200";
            case "ROUCH_GRIND_ANGEL": return "bg-rose-700 text-rose-200";
            case "FISHING_GRIND_ANGEL": return "bg-fuchsia-700 text-fuchsia-200";
            case "CHEMFER": return "bg-pink-700 text-pink-200";
            case "GRIND_UNDER_CUT": return "bg-orange-700 text-orange-200";
            case "WARPING": return "bg-yellow-700 text-yellow-200";
            case "GRIND_WARPING_MARK": return "bg-lime-700 text-lime-200";
            case "DUBER": return "bg-green-700 text-green-200";
            case "DUBER_WARPING_MARK": return "bg-emerald-700 text-emerald-200";
        }
    }, [process])

    return <div className={`rounded-full ${color} w-max ${padding}`}>
        <span className="text-sm font-semibold">{getProcessLabel(process)}</span>
    </div>
}